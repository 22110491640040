import React from 'react';
import * as styles from './TestimonialHero.module.scss';
import classNames from 'classnames';
import {Testimonial} from "../Testimonial";
import {componentRef} from "../../functions/componentRef";

export function TestimonialHero({className, appearance = 'hero', ...otherProps}) {
    return <div className={classNames(className, styles.root)} {...componentRef('testimonial-hero')}>
        <div className={styles.wrapper}>
            <Testimonial appearance={appearance} className={styles.content} {...otherProps}/>
        </div>
    </div>
}
